import { gql } from '@apollo/client';

// export const REAL_TIME_METRIC = gql`
//   query RealTime_GetMetric($timePeriod: TimePeriod) {
//     RealTime_GetMetric(timePeriod: $timePeriod) {
//       callMetric {
//         lastUpdate
//         overall {
//           totalCall
//           totalPickUpCall
//           averageTimeDuration
//           averagePickUpCallPerSalesman
//           averageCallDurationPerSalesman
//         }
//         topBusinessUnits {
//           totalPickUpCall
//           BUName
//           rank
//         }
//         topBusinessAssociates {
//           totalPickUpCall
//           BAName
//           rank
//         }
//         topSaleMans {
//           userId
//           userName
//           totalPickUpCall
//           lastUpdate
//         }
//       }
//       cr45Metric {
//         today
//         thisWeek
//         thisMonth
//         lastUpdate
//       }
//     }
//   }
// `;

export const REAL_TIME_METRIC = gql`
  query RealTime_GetMetric($timePeriod: String!) {
    RealTime_GetMetric(timePeriod: $timePeriod) {
      saleMetric {
        callMetric {
          overall {
            totalCall
            totalPickUpCall
            averageTimeDuration
            averagePickUpCallPerSalesman
            averageCallDurationPerSalesman
          }
          topBusinessUnits {
            totalPickUpCall
            BUName
            rank
          }
          topBusinessAssociates {
            totalPickUpCall
            BAName
            rank
          }
          topSaleMans {
            userId
            userName
            totalPickUpCall
            lastUpdate
          }
          lastUpdate
        }
      }
      performanceMetric {
        timePeriod
        overall {
          cr45
          revenue
          kpiRevenue
          revenueBySaleman
        }
        cr45 {
          top3BusinessUnits {
            businessUnitName
            businessUnitId
            value
          }
          top5BusinessUnits {
            businessUnitName
            businessUnitId
            value
          }
        }
        revenue {
          top3BusinessUnits {
            businessUnitName
            businessUnitId
            value
          }
          top5BusinessUnits {
            businessUnitName
            businessUnitId
            value
          }
          top10Salemans {
            salemanName
            salemanId
            value
          }
        }
        kpiRevenue {
          top3BusinessUnits {
            businessUnitName
            businessUnitId
            value
          }
          top5BusinessUnits {
            businessUnitName
            businessUnitId
            value
          }
          top10Salemans {
            salemanName
            salemanId
            value
          }
        }
        lastUpdate
      }
    }
  }
`;
