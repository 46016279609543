import { RealtimeDashboard } from '@/modules/real-time/screens/RealtimeDashboard';
import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { config } from './config';
import { Layout } from './components';
import { Authorize, useDocumentTitle } from './core';
import { Report, ReportItem } from './modules';
import './App.css';
import './index.css';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { DropdownMenu } from './modules/layouts/DropdownMenu';
import { MenuItemList } from './modules/menu-items/UI';
import { Sidebar } from './components/Sidebar';
import { useGetTitle } from './modules/layouts/sidebar/hooks/useGetTitle';
import { UIndexDashboard } from './modules/dashboard/UI';
import { PERMISSIONS } from './core/constants/permission.constant';
import { AppProvider } from './core/context/AppContext';

const { Header, Content, Footer } = Layout;

const AppList: React.FC<unknown> = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { title, setTitle, hanldeTittle } = useGetTitle();
  const location = useLocation();
  const pathName = location.pathname;
  const path = pathName.slice(1);

  useDocumentTitle();
  useEffect(() => {
    hanldeTittle(path);
  }, [pathName]);
  return (
    <AppProvider>
      <Layout style={{ minHeight: '100vh' }}>
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} setTitle={setTitle} />
        <Layout className='site-layout'>
          <Header className='site-layout-background px-3 flex items-center'>
            {React.createElement(collapsed ? RightOutlined : LeftOutlined, {
              onClick: () => setCollapsed(!collapsed),
            })}
            <Typography.Title className='ml-2 mt-2' level={4}>
              {title}
            </Typography.Title>
            <DropdownMenu />
          </Header>
          <Content style={{ margin: '24px 16px', minHeight: 'calc(100vh - 120px)' }}>
            <Routes>
              <Route path='/' element={<Report />} />
              <Route path='/menu-items' element={<MenuItemList />} />
              <Route path='/dashboard-management' element={<UIndexDashboard />} />
              <Route path={'/real-time/call-metrics'} element={<RealtimeDashboard />} />
              <Route path='*' element={<ReportItem />} />
            </Routes>
          </Content>
          <Footer style={{ textAlign: 'center' }}>{config.footer.text}</Footer>
        </Layout>
      </Layout>
    </AppProvider>
  );
};
export const App = Authorize(AppList, PERMISSIONS.APP.VIEW);
