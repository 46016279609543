import '@/modules/real-time/scss/realtime-dashboard.scss';
import { useRealtimeDashboardViewModel } from '@/modules/real-time/viewmodels/realtime-dashboard.view-model';
import { Bar, Column } from '@ant-design/charts';
import { Col, Row } from 'antd';
import { Overall } from '../components/Overall';

export const RealtimeDashboard = () => {
  const { filter, setFilter, realtimeMetric } = useRealtimeDashboardViewModel(30000);
  return (
    <>
      <Overall
        call={realtimeMetric.saleMetric.callMetric.overall}
        performance={realtimeMetric.performanceMetric.overall}
        filter={filter}
        setFilter={setFilter}
      />
      <Row id='chart-draw' gutter={16} className='mt-2'>
        <Col span={9} id='main-report-left'>
          <Row gutter={10}>
            <Col span={12}>
              <div className='bg-white'>
                <h4 className='p-1 font-light text-sm'>
                  Top đầu và cuối BAs theo số cuộc gọi nhấc máy
                </h4>
                <Bar
                  height={330}
                  theme='academy'
                  xField='BAName'
                  yField='totalPickUpCall'
                  data={realtimeMetric.saleMetric.callMetric.topBusinessAssociates}
                  colorField={'#e31f26'}
                  color={(item: any) => {
                    const rank = realtimeMetric.saleMetric.callMetric.topBusinessAssociates.filter(
                      (ba) => ba.BAName === item.BAName,
                    )[0].rank;
                    if (rank > 3) {
                      return '#000000';
                    } else {
                      return '#e31f26';
                    }
                  }}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className='bg-white'>
                <h4 className='p-1 font-light text-sm'>
                  Top đầu và cuối BUs theo số cuộc gọi nhấc máy
                </h4>
                <Bar
                  height={330}
                  theme='academy'
                  className='bg-white'
                  xField='BUName'
                  yField='totalPickUpCall'
                  colorField={'#e31f26'}
                  data={realtimeMetric.saleMetric.callMetric.topBusinessUnits}
                  color={(item: any) => {
                    const rank = realtimeMetric.saleMetric.callMetric.topBusinessUnits.filter(
                      (bu) => bu.BUName === item.BUName,
                    )[0].rank;
                    if (rank > 3) {
                      return '#000000';
                    } else {
                      return '#e31f26';
                    }
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col span={24}>
              <div className='bg-white'>
                <h4 className='text-sm font-light p-1'>
                  Top đầu và cuối BUs theo số cuộc gọi nhấc máy
                </h4>
                <Column
                  height={250}
                  colorField={'#e31f26'}
                  data={realtimeMetric.saleMetric.callMetric.topSaleMans}
                  xField='userName'
                  yField='totalPickUpCall'
                  className='bg-white mt-2'
                  theme='academy'
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={15} id='main-report-right'>
          <Row id='ba-performance-chart-data' gutter={10}>
            <Col span={8}>
              <div className='bg-white'>
                <h4 className='text-sm font-light p-1'>Top 3 BUs theo doanh thu</h4>
                <Bar
                  height={145}
                  colorField={'#e31f26'}
                  theme='academy'
                  className='bg-white'
                  xField='BAName'
                  yField='totalRevenue'
                  data={realtimeMetric.performanceMetric.revenue.top3BusinessUnits}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className='bg-white'>
                <h4 className='text-sm font-light p-1'>Top 3 BAs theo doanh thu</h4>
                <Bar
                  height={145}
                  colorField={'#e31f26'}
                  theme='academy'
                  className='bg-white'
                  xField='BUName'
                  yField='totalKPIRevenue'
                  data={realtimeMetric.performanceMetric.kpiRevenue.top3BusinessUnits}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className='bg-white'>
                <h4 className='text-sm font-light p-1'>Top 3 BAs theo CR45</h4>
                <Bar
                  height={145}
                  colorField={'#e31f26'}
                  theme='academy'
                  className='bg-white'
                  xField='BAName'
                  yField='value'
                  data={realtimeMetric.performanceMetric.cr45.top3BusinessUnits}
                />
              </div>
            </Col>
          </Row>
          <Row id='bu-perfrmance-chart-data' gutter={10} className='mt-2'>
            <Col span={8}>
              <div className='bg-white'>
                <h4 className='text-sm font-light p-1'>Top 5 BUs theo tiến độ doanh thu</h4>
                <Bar
                  height={145}
                  colorField={'#e31f26'}
                  theme='academy'
                  className='bg-white'
                  xField='BAName'
                  yField='totalRevenue'
                  data={realtimeMetric.performanceMetric.revenue.top5BusinessUnits}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className='bg-white'>
                <h4 className='text-sm font-light p-1'>Top 5 BUs theo tiến độ KPI doanh thu</h4>
                <Bar
                  height={145}
                  colorField={'#e31f26'}
                  theme='academy'
                  className='bg-white'
                  xField='BAName'
                  yField='totalKPIRevenue'
                  data={realtimeMetric.performanceMetric.kpiRevenue.top5BusinessUnits}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className='bg-white'>
                <h4 className='text-sm font-light p-1'>Top 5 BUs theo CR45</h4>
                <Bar
                  height={145}
                  colorField={'#e31f26'}
                  theme='academy'
                  className='bg-white'
                  xField='BAName'
                  yField='value'
                  data={realtimeMetric.performanceMetric.cr45.top5BusinessUnits}
                />
              </div>
            </Col>
          </Row>
          <Row className='mt-2' gutter={10}>
            <Col span={12}>
              <div className='bg-white'>
                <h4 className='text-sm font-light p-1'>Top 10 Saleman theo KPI doanh thu</h4>
                <Column
                  height={250}
                  colorField={'#e31f26'}
                  data={realtimeMetric.performanceMetric.revenue.top10Salemans}
                  xField='userName'
                  yField='revenue'
                  className='bg-white'
                  theme='academy'
                />
              </div>
            </Col>
            <Col span={12}>
              <div className='bg-white'>
                <h4 className='text-sm font-light p-1'>Top 10 Saleman theo KPI doanh thu</h4>
                <Column
                  height={250}
                  colorField={'#e31f26'}
                  data={realtimeMetric.performanceMetric.kpiRevenue.top10Salemans}
                  xField='userName'
                  yField='KPIRevenue'
                  className='bg-white'
                  theme='academy'
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
