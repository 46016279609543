import { useBaseViewModel } from '@/common';
import { RealTimeMetricEntity } from '@/domain/entities/real-time';
import {
  ChartFormat,
  PerformanceMetricType,
} from '@/domain/entities/real-time/performance-metric.entity';
import { CallMetricType } from '@/domain/entities/real-time/sale-metric.entity';
import { GetTotalPickupCallMetric } from '@/domain/use-cases/real-time/get-total-pickup-call-metric';
import { RealTimeRepository } from '@/modules/real-time/adapters/repositories';
import { useEffect, useRef, useState } from 'react';

export type TimePeriod = 'today' | 'thisWeek' | 'thisMonth';

export type RealTimeMetricProps = RealTimeMetricEntity;

export type RealTimeDashboardViewModelProps = {
  setFilter: (timePeriod: TimePeriod) => void;
  filter: {
    timePeriod: TimePeriod;
  };
  realtimeMetric: RealTimeMetricEntity;
  loading: boolean;
  error: any;
};

export const useRealtimeDashboardViewModel = (
  interval = 30000,
): RealTimeDashboardViewModelProps => {
  let lastTimePeriod = localStorage.getItem('timePeriod') as TimePeriod;
  if (!lastTimePeriod) {
    lastTimePeriod = 'today';
  }

  const [timePeriod, setTimePeriod] = useState<{
    timePeriod: TimePeriod;
  }>({
    timePeriod: lastTimePeriod as TimePeriod,
  });

  const timePeriodRef = useRef(timePeriod);
  const setFilter = (timePeriod: TimePeriod) => {
    setTimePeriod({ timePeriod: timePeriod });
    localStorage.setItem('timePeriod', timePeriod);
  };

  const [callMetric, setCallMetric] = useState<CallMetricType>({
    overall: {
      totalCall: 0,
      totalPickUpCall: 0,
      averageTimeDuration: 0,
      averageCallPerSalesman: 0,
      averagePickUpCallPerSalesman: 0,
      averageCallDurationPerSalesman: 0,
    },
    topBusinessUnits: [],
    topBusinessAssociates: [],
    topSaleMans: [],
    lastUpdate: '',
  });

  const [performanceMetric, setPerformanceMetric] = useState<PerformanceMetricType>({
    timePeriod: lastTimePeriod,
    overall: {
      cr45: 0,
      revenue: 0,
      kpiRevenue: 0,
      revenueBySaleman: 0,
    },
    cr45: {
      top3BusinessUnits: [],
      top5BusinessUnits: [],
      chartFormat: ChartFormat.DEFAULT,
    },
    revenue: {
      top3BusinessUnits: [],
      top5BusinessUnits: [],
      top10Salemans: [],
      chartFormat: ChartFormat.CURRENCY,
    },
    kpiRevenue: {
      top3BusinessUnits: [],
      top5BusinessUnits: [],
      top10Salemans: [],
      chartFormat: ChartFormat.PERCENTAGE,
    },
    lastUpdate: '',
  });

  const [realtimeMetric, setRealtimeMetric] = useState<RealTimeMetricProps>({
    performanceMetric,
    saleMetric: {
      callMetric,
    },
  });

  useEffect(() => {
    setRealtimeMetric({
      performanceMetric,
      saleMetric: {
        callMetric,
      },
    });
  }, [performanceMetric, callMetric]);

  const useCase = new GetTotalPickupCallMetric(new RealTimeRepository());
  const { loading, error, catchAction } = useBaseViewModel();

  const fetchRealtimeData = () => {
    console.log('Fetch realtime data');
    catchAction(async () => {
      const data = await useCase.run(timePeriodRef.current.timePeriod);
      setCallMetric(data.saleMetric.callMetric);
      // setPerformanceMetric(data.performanceMetric);
    });
  };
  let fetchInterval: NodeJS.Timeout;
  const registerInterval = () => {
    fetchInterval = setInterval(() => {
      fetchRealtimeData();
    }, interval);
  };

  useEffect(() => {
    timePeriodRef.current = timePeriod;
    fetchRealtimeData();
  }, [timePeriod]);

  useEffect(() => {
    fetchRealtimeData();
    registerInterval();
    return () => {
      clearInterval(fetchInterval);
    };
  }, [interval]);

  return {
    setFilter,
    realtimeMetric,
    filter: timePeriod,
    loading,
    error,
  };
};
