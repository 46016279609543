import { Col, Row, Statistic, StatisticProps } from 'antd';
import React, { useEffect } from 'react';
import CountUp from 'react-countup';
import { TimePeriod } from '../viewmodels/realtime-dashboard.view-model';
import { Filter } from './particles/Filter';
import { PerformanceOverallMetricType } from '@/domain/entities/real-time/performance-metric.entity';
import { CallOverViewType } from '@/domain/entities/real-time/sale-metric.entity';

type OverallProps = {
  call: CallOverViewType;
  performance: PerformanceOverallMetricType;
  filter: {
    timePeriod: TimePeriod;
  };
  setFilter: (filter: TimePeriod) => void;
};

type CardProps = {
  title: string;
  value: number;
  formatter: StatisticProps['formatter'];
};

export const Overall: React.FC<OverallProps> = (props) => {
  const formatter: StatisticProps['formatter'] = (value) => (
    <CountUp end={value as number} separator=',' />
  );
  const secondsFormater: StatisticProps['formatter'] = (value) => {
    const num = value as number;
    if (num < 60) {
      return `${num}s`;
    } else {
      return `${Math.floor(num / 60)} phút`;
    }
  };
  const [callOverallCard, setCallOverallCard] = React.useState<CardProps[]>([]);
  useEffect(() => {
    setCallOverallCard([
      {
        title: 'Tổng cuộc gọi',
        value: props.call.totalCall,
        formatter,
      },
      {
        title: 'Tổng cuộc gọi nhấc máy',
        value: props.call.totalPickUpCall,
        formatter,
      },
      {
        title: 'Tổng thời gian gọi',
        value: props.call.averageTimeDuration,
        formatter: secondsFormater,
      },
      {
        title: 'Số cuộc gọi trên salesman',
        value: props.call.averageCallPerSalesman,
        formatter,
      },
      {
        title: 'Số cuộc gọi nhấc máy trên salesman',
        value: props.call.averagePickUpCallPerSalesman,
        formatter,
      },
      {
        title: 'Thời lượng gọi trên salesman',
        value: props.call.averageCallDurationPerSalesman,
        formatter: secondsFormater,
      },
    ]);
  }, [props.call]);

  return (
    <>
      <Row id={'top-overall'} gutter={16}>
        <Col span={8}>
          <Row gutter={10}>
            {callOverallCard &&
              callOverallCard.map((item, index) => (
                <Col span={8} key={index}>
                  <div className='bg-white mb-2 p-2'>
                    <Statistic title={item.title} value={item.value} formatter={item.formatter} />
                  </div>
                </Col>
              ))}
          </Row>
        </Col>
        <Col span={16}>
          <Row gutter={10}>
            <Col span={8}>
              <Row gutter={5}>
                <Col span={12}>
                  <div className='bg-white p-2 mb-2'>
                    <Statistic
                      title='Doanh thu tổng'
                      value={props.performance.revenue}
                      formatter={formatter}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div className='bg-white p-2 mb-2'>
                    <Statistic
                      title='Doanh thu KPI'
                      value={props.performance.kpiRevenue}
                      formatter={formatter}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div className='bg-white p-2 mb-2'>
                    <Statistic title='CR45' value={props.performance.cr45} formatter={formatter} />
                  </div>
                </Col>
                <Col span={12}>
                  <div className='bg-white p-2 mb-2'>
                    <Statistic
                      title='Doanh thu trên Salemans'
                      value={props.performance.revenueBySaleman}
                      formatter={formatter}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col id='filter' span={16}>
              <Filter setFilter={props.setFilter} filter={props.filter} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
