import { TimePeriod } from '@/modules/real-time/viewmodels/realtime-dashboard.view-model';
import { Button, Col } from 'antd';
import React from 'react';

type Props = {
  setFilter: (timePeriod: TimePeriod) => void;
  filter: {
    timePeriod: TimePeriod;
  };
};
export const Filter: React.FC<Props> = ({ setFilter, filter }: Props) => {
  return (
    <>
      <Button
        onClick={() => {
          setFilter('today');
        }}
        className={filter.timePeriod === 'today' ? 'timePeriodActive' : ''}
        size='large'
      >
        Hôm nay
      </Button>
      &nbsp;
      <Button
        className={filter.timePeriod === 'thisWeek' ? 'timePeriodActive' : ''}
        onClick={() => {
          setFilter('thisWeek');
        }}
        type='default'
        size='large'
      >
        Tuần này
      </Button>
      &nbsp;
      <Button
        className={filter.timePeriod === 'thisMonth' ? 'timePeriodActive' : ''}
        onClick={() => {
          setFilter('thisMonth');
        }}
        type='default'
        size='large'
      >
        Tháng này
      </Button>
    </>
  );
};
