import { RealTimeMetricEntity } from '@/domain/entities/real-time';
import { IRealTimeRepository } from '@/domain/ports/real-time/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { REAL_TIME_METRIC } from '@/modules/real-time/graphql';

enum TimePeriod {
  TODAY = 'today',
  THIS_WEEK = 'thisWeek',
  THIS_MONTH = 'thisMonth',
}
export class RealTimeRepository implements IRealTimeRepository {
  async getRealTimeMetric(timePeriod: string): Promise<RealTimeMetricEntity> {
    const timePeriodKey = TimePeriod[timePeriod as keyof typeof TimePeriod];
    const response = await HttpClientAdapter.query<{
      RealTime_GetMetric: RealTimeMetricEntity;
    }>({
      query: REAL_TIME_METRIC,
      variables: {
        timePeriod: timePeriodKey ?? ('TODAY' as string),
      },
    });
    return {
      ...response.RealTime_GetMetric,
    };
  }
}
