export enum ChartFormat {
  DEFAULT = 'default',
  PERCENTAGE = 'percentage',
  CURRENCY = 'currency',
}

export type BusinessUnitType = {
  businessUnitName: string;
  businessUnitId: string;
  value: number;
};

export type CR45MetricType = {
  top3BusinessUnits: BusinessUnitType[];
  top5BusinessUnits: BusinessUnitType[];
  chartFormat: ChartFormat;
};

export type SalemanType = {
  salemanName: string;
  salemanId: string;
  value: number;
};

export type RevenueMetricType = {
  top3BusinessUnits: BusinessUnitType[];
  top5BusinessUnits: BusinessUnitType[];
  top10Salemans: SalemanType[];
  chartFormat: ChartFormat;
};

export type KPIRevenueMetricType = {
  top3BusinessUnits: BusinessUnitType[];
  top5BusinessUnits: BusinessUnitType[];
  top10Salemans: SalemanType[];
  chartFormat: ChartFormat;
};

export type PerformanceOverallMetricType = {
  cr45: number;
  revenue: number;
  kpiRevenue: number;
  revenueBySaleman: number;
};

export type PerformanceMetricType = {
  timePeriod: string;
  overall: PerformanceOverallMetricType;
  cr45: CR45MetricType;
  revenue: RevenueMetricType;
  kpiRevenue: KPIRevenueMetricType;
  lastUpdate: string;
};
